import React from 'react'
import classnames from 'classnames/bind'
import Triangle from 'components/shared/Triangle'
import ListCardContent from 'components/shared/CardWithArrowLink/ListCardContent'
import CardWithArrowLink from 'components/shared/CardWithArrowLink'

import useRefs from 'hooks/useRefs'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const CardsGrid = ({
  className,
  cards = [],
  bottomLink,
  decoration,
  customCardComponent,
  customCardProps,
  singleColumnOnTablet,
  linkText,
}) => {
  const cardsRefs = useRefs(cards.length)
  const { isList, fullCardLink, cover } = customCardProps

  const triangles = [
    {
      side: 'left',
      type: 'top-left',
    },
    {
      side: 'right',
      type: 'bottom-right',
    },
  ]

  const Component = customCardComponent || ListCardContent

  return (
    <div
      className={cx(css.CardsGrid, className, {
        singleColumnOnTablet,
        isList,
      })}
    >
      {decoration &&
        triangles.map(({ side, type }, id) => (
          <Triangle className={cx(css.triangle, side)} key={id} type={type} />
        ))}
      {cards &&
        cards.map((card, i) => {
          const { link, ...rest } = card
          if (link) {
            link.text = linkText ? linkText : link.text
          }
          const cardProps = {
            ref: cardsRefs.current[i],
            image: card.coverImage || card.image || card.cover,
            ...((fullCardLink || bottomLink) && link && { link }),
          }

          const coverProps =
            cover && cover.withLink ? { ...cover, link } : cover
          return (
            <div key={`card-${card.title}-${i}`} className={css.cardContainer}>
              <CardWithArrowLink
                {...rest}
                cover={coverProps}
                isResourcesGrid
                bottomLink={bottomLink}
                customCardProps={{ ...customCardProps, cover: coverProps }}
                {...cardProps}
              >
                <Component
                  className={css.cardContainer}
                  link={link}
                  {...customCardProps}
                  {...rest}
                />
              </CardWithArrowLink>
            </div>
          )
        })}
    </div>
  )
}

export default CardsGrid
