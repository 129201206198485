import React from 'react'
import Image from 'components/shared/Image'
import classnames from 'classnames/bind'
import css from './styles.module.scss'

const cx = classnames.bind(css)

const Triangle = ({ className, withDots, withMobileDots, type, reversed }) => {
  return (
    <div
      className={cx(css.Triangle, className, type, reversed, {
        withMobileDots,
      })}
    >
      <div className={css.wrapper}>
        {withDots && (
          <Image
            src="/assets/decoration/dots.svg"
            alt="dots"
            className={css.dots}
            media={{ width: 97, height: 30 }}
          />
        )}
        <Image
          src="/assets/decoration/triangle.svg"
          alt="triangle"
          className={css.image}
          media={{ width: 638, height: 638 }}
        />
      </div>
    </div>
  )
}

Triangle.defaultProps = {
  type: 'top-right',
}

export default Triangle
