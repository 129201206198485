import React from 'react'
import classnames from 'classnames/bind'

import Image from 'components/shared/Image'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const CoverImage = ({ className, ...rest }) => {
  return (
    <Image
      className={cx(css.CoverImage, className)}
      fluid
      fit="cover"
      {...rest}
    />
  )
}

CoverImage.defaultProps = {
  ratio: 1,
}

export default CoverImage
