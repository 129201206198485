import React from 'react'
import classnames from 'classnames/bind'

import { Title, Text, Label } from 'components/typo'
import Author from 'components/shared/Author'
import Tag from 'components/shared/Tag'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const ListCardContent = ({
  className,
  label,
  title,
  categories,
  theme,
  layout,
  author,
  featured,
  description,
  isList,
}) => {
  return (
    <div
      className={cx(className, css.ListCardContent, layout, {
        isList,
      })}
    >
      {label && (
        <div className={css.labelWrapper}>
          <div className={cx(css.dash, theme)} />
          <Label theme={theme} className={css.label}>
            {label}
          </Label>
        </div>
      )}
      {categories && (
        <ul className={css.tags}>
          {categories.map((category) => (
            <Tag className={css.tag} key={category} tagName="li">
              <Label className={css.tagLabel}>{category}</Label>
            </Tag>
          ))}
        </ul>
      )}
      {title && (
        <Title className={css.title} size="smaller" tag="h2">
          {title}
        </Title>
      )}

      {description && (
        <Text className={css.text} size={featured ? 'extra-small' : 'small'}>
          {description}
        </Text>
      )}

      {author && <Author small className={css.author} {...author} />}
    </div>
  )
}

export default ListCardContent
