import React from 'react'
import classnames from 'classnames/bind'

import { Text } from 'components/typo'
import Image from 'components/shared/Image'
import Link from 'components/shared/Link'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const Author = ({
  className,
  image,
  theme,
  small,
  name,
  date,
  slug,
  coauthors,
}) => {
  const formattedDate = new Date(date).toLocaleString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })

  return (
    <div className={cx(css.Author, className, { small })}>
      {image && (
        <Image
          format="thumbnail"
          className={css.image}
          fluid
          {...image}
          alt=""
        />
      )}
      {coauthors?.length > 0 &&
        coauthors.map(
          (author) =>
            author.image && (
              <Image
                format="thumbnail"
                className={css.image}
                fluid
                {...author.image}
                alt=""
              />
            )
        )}
      <div className={css.content}>
        <div className={css.names}>
          {slug && (
            <Link
              href={`/user/${slug}`}
              style={{ textDecoration: 'none', paddingRight: 8 }}
            >
              {name && (
                <Text size={small ? 'smaller' : 'small'} theme={theme}>
                  {name}
                </Text>
              )}
            </Link>
          )}
          {coauthors?.length > 0 &&
            coauthors.map(
              (author) =>
                author.slug && (
                  <Link
                    key={author.id}
                    href={`/user/${author.slug}`}
                    style={{ textDecoration: 'none', paddingRight: 8 }}
                  >
                    {author.name && (
                      <Text size={small ? 'smaller' : 'small'} theme={theme}>
                        {author.name}
                      </Text>
                    )}
                  </Link>
                )
            )}
        </div>

        {date && (
          <Text size={small ? 'legend' : 'smaller'}>{formattedDate}</Text>
        )}
      </div>
    </div>
  )
}

Author.defaultProps = {
  theme: 'bay-blue',
}

export default Author
