import React, { useRef, forwardRef, cloneElement, useCallback } from 'react'
import Link from 'components/shared/Link'
import classnames from 'classnames/bind'
import delve from 'dlv'

import ArrowLink from 'components/shared/ArrowLink'

import useCardMouseAnimations from 'hooks/useCardMouseAnimations'
import useSetStyle from 'hooks/useSetStyle'
import CardCover from './CardCover'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const CardWithArrowLink = ({
  children,
  className,
  forwardRef,
  layout,
  arrowLayout,
  image,
  bottomLink,
  link,
  theme,
  customCardProps,
  ...rest
}) => {
  const customCardPropsWithDefault = {
    ...CardWithArrowLinkProps.customCardProps,
    ...customCardProps,
  }

  const cover = delve(customCardPropsWithDefault, 'cover')
  const featured = delve(customCardPropsWithDefault, 'featured', false)
  const backgroundType = delve(customCardPropsWithDefault, 'backgroundType')
  const as = delve(link, 'as') || delve(cover, 'link.as')
  const href = delve(link, 'href') || delve(cover, 'link.href')
  const text = delve(link, 'text')

  const componentRef = useRef()
  const cardRef = useRef()
  const linkRef = useRef()
  const backgroundRef = useRef()
  const lineRef = useRef()
  const coverRef = useRef()
  const coverImgRef = useRef()

  const setRef = useCallback((node) => {
    forwardRef && (forwardRef.current = node)
    componentRef.current = node
  }, [])

  const setLinkStyle = useSetStyle(linkRef)
  const setLineStyle = useSetStyle(lineRef)
  const setCoverStyle = useSetStyle(coverRef)
  const setCoverImgStyle = useSetStyle(coverImgRef)

  const transformOrigin = featured ? 'center' : 'bottom'
  const span = featured ? 0.01 : 0.03
  const [isHover, mouseEvents] = useCardMouseAnimations(
    {
      cardRef,
      backgroundRef,
      span,
    },
    (progress) => {
      if (setLinkStyle) {
        setLinkStyle({
          y: 7 * progress,
        })
        setLineStyle({
          scale: span * progress + 1,
          transformOrigin: 'center',
        })
        setCoverStyle({
          scale: span * progress + 1,
        })
        setCoverImgStyle({
          scale: -span * progress + 1.04,
        })
      }
    }
  )

  const classNames = cx(css.CardWithArrowLink, layout, className, {
    withLink: link,
  })
  const Wrapper = link ? Link : 'li'
  const CoverWrapper = cover && cover.withLink ? Link : 'div'

  return (
    <Wrapper
      ref={setRef}
      href={href}
      as={as}
      className={classNames}
      {...mouseEvents}
    >
      <div className={css.backgroundContainer}>
        <div
          className={cx(css.background, backgroundType)}
          ref={backgroundRef}
        />
      </div>
      <div ref={cardRef} className={cx(css.cardContainer, { featured })}>
        {cover && (
          <CoverWrapper
            ref={coverRef}
            href={href}
            className={cx(css.coverImageContainer, cover.size, transformOrigin)}
          >
            <div ref={coverImgRef} className={css.coverImage}>
              <CardCover
                featured={featured}
                image={image}
                {...cover}
                {...rest}
              />
            </div>
          </CoverWrapper>
        )}
        <div className={css.cardContent}>
          {children &&
            cloneElement(children, {
              isHover,
              layout,
              theme,
              ...customCardProps,
            })}
        </div>
        {link && bottomLink && (
          <div ref={linkRef} className={css.link}>
            <div ref={lineRef} className={css.line} />
            <ArrowLink
              isActive={isHover}
              forwardRef={linkRef}
              link={link}
              theme={theme}
              layout={arrowLayout}
              className={css.arrowLink}
            >
              {text}
            </ArrowLink>
          </div>
        )}
      </div>
    </Wrapper>
  )
}

export const CardWithArrowLinkProps = {
  theme: 'green',
  customCardProps: {
    fullCardLink: true,
    isList: true,
    featured: false,
    backgroundType: 'type1',
  },
}

CardWithArrowLink.defaultProps = {
  ...CardWithArrowLinkProps,
}

export default forwardRef((props, ref) => (
  <CardWithArrowLink {...props} forwardRef={ref} />
))
