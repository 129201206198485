import React from 'react'

import classnames from 'classnames/bind'
import css from './styles.module.scss'

const cx = classnames.bind(css)

const Ratio = ({ className, ratio, children, forwardRef, forwardProps }) => {
  return (
    <div
      className={cx(css.Ratio, className)}
      style={{ paddingBottom: `${ratio * 100}%` }}
      ref={forwardRef}
      {...forwardProps}
    >
      <div className={css.children}>{children}</div>
    </div>
  )
}

Ratio.defaultProps = {}

export default Ratio
