import React from 'react'
import classnames from 'classnames/bind'
import CoverImage from 'components/shared/CoverImage'
import Ratio from 'components/shared/Ratio'
import { Text } from 'components/typo'
import DefaultCover from '../DefaultCover'
import { ReactComponent as PlayIcon } from './play-icon.svg'

import css from './styles.module.scss'

const cx = classnames.bind(css)

function trimDuration(duration) {
  const match = duration.match(/PT(\d+H)?(\d+M)?(\d+S)?/)
  const [hours, minutes, seconds] = match.slice(1).map((entry) => {
    if (entry) {
      const unit = Number(entry.replace(/\D/, ''))

      return unit >= 10 ? unit : `0${unit}`
    }
  })

  const formattedString = [hours, minutes, seconds].reduce((str, value) => {
    if (value) return `${str}${str !== '' ? ':' : ''}${value}`

    return str
  }, '')

  return formattedString
}

const CardCover = ({ className, image, isVideo, featured, duration }) => {
  const classNames = cx(css.CardCover, className, { featured })

  return (
    <Ratio ratio={9 / 16} className={classNames}>
      {isVideo && <PlayIcon className={css.icon} />}
      {duration && (
        <div className={css.duration}>
          <Text theme="white" size="smaller">
            {trimDuration(duration)}
          </Text>
        </div>
      )}
      {image ? <CoverImage media {...image} /> : <DefaultCover />}
    </Ratio>
  )
}

export default CardCover
