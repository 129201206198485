import React from 'react'
import classnames from 'classnames/bind'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const Tag = ({ className, tagName, children }) => {
  const Component = tagName

  return <Component className={cx(css.Tag, className)}>{children}</Component>
}

Tag.defaultProps = {
  tag: 'li',
}

export default Tag
