import useIsomorphicLayoutEffect from 'hooks/useIsomorphicLayoutEffect'
import useSpring from 'hooks/useSpring'
import useOnMouseEnter from 'hooks/useOnMouseEnter'
import useSetStyle from 'hooks/useSetStyle'

const useCardMouseAnimations = (
  { cardRef, backgroundRef, span },
  onProgress
) => {
  const [isHover, onMouseEnter, onMouseLeave] = useOnMouseEnter(cardRef)

  const setBackgroundStyle = useSetStyle(backgroundRef)
  const [setSpring] = useSpring({
    progress: 0,
    config: {
      friction: 25,
      rigidity: 0.15,
    },
    onUpdate: ({ progress }, prevValue) => {
      if (progress !== prevValue.progress) {
        setBackgroundStyle({
          scale: span * progress + 1,
          transformPerspective: 100,
        })

        if (onProgress) onProgress(progress)
      }
    },
  })

  useIsomorphicLayoutEffect(() => {
    setSpring({
      progress: isHover ? 1 : 0,
    })
  }, [isHover])

  return [
    isHover,
    { onMouseEnter, onMouseLeave, onFocus: onMouseEnter, onBlur: onMouseLeave },
  ]
}

export default useCardMouseAnimations
